import Grid from '../Grid';
import ButtonGrey from '../ButtonGrey';

import style from './ContactCard.module.css';

const ContactCard = ({ src, name, about, link }) => {
    const handleOpenLink = () => {
        window.open(link, '_blank');
    }

    return (
        <Grid emptyBackground={true} customWrapper={style.Wrapper}>
            <img src={src} alt='' />
            <div className={style.Name}>{name}</div>

            <div className={style.Text}>
                {about}
            </div>

            <ButtonGrey onClick={handleOpenLink} text={'Open contact'} />
        </Grid>
    )
}

export default ContactCard