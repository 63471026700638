import GridTabs, { GridTab } from "components/GridTabs"
//Pages
import Log from "./Log"
import AddDomain from "./AddDomain"
import AddAddress from "./AddAddress"
//Tabs
import Logs from "./tabs/Logs"
import Drainers from "./tabs/Drainers"
import DrainerWithOffer from "./tabs/DrainerWithOffer"
import Address from "./tabs/Address"

const Main = () => {
    return (
        <GridTabs
            selected='logs'
        >
            <GridTab name='Logs' tab='logs' element={<Logs />} />
            <GridTab name='Address' tab='address' element={<Address />} />
            <GridTab name='Drainer' tab='drainer' element={<Drainers />} />
            <GridTab name='Offer + Drainer' tab='offer' element={<DrainerWithOffer />} />
        </GridTabs>
    )
}

export default { Main, Log, AddDomain, AddAddress }