import { useNavigate } from 'react-router-dom';

import Table from "components/Table"
import Status from "components/Status";
import Country from "components/Country";

import domainShorter from 'helper/domainShorter';

import style from './Logs.module.css';

const Logs = () => {
    const navigate = useNavigate();

    const goMore = (hid) => {
        navigate(`/ton/log/${hid}`)
    }

    return (
        <Table.Thead names={['From', 'Domain', 'Activity', 'Ago']}>
            <Table.Tbody
                onClick={() => { }}
                list={[
                    <Country code='RU' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status newClass={style.Status} />,
                    '1 min'
                ]}
            />
            <Table.Tbody
                onClick={() => { }}
                list={[
                    <Country code='UK' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status action={'openSite'} newClass={style.Status} />,
                    '1 hour'
                ]}
            />
            <Table.Tbody
                onClick={() => { }}
                list={[
                    <Country code='FR' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status action={'successOnSend'} newClass={style.Status} />,
                    '1 day'
                ]}
            />
            <Table.Tbody
                onClick={() => goMore('f68c1b9')}
                list={[
                    <Country code='TR' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status action={'openModal'} newClass={style.Status} />,
                    '100 days'
                ]}
            />
            <Table.Tbody
                onClick={() => { }}
                list={[
                    <Country code='US' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status action={'initWallet'} newClass={style.Status} />,
                    '1 year'
                ]}
            />
            <Table.Tbody
                onClick={() => { }}
                list={[
                    <Country code='US' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status action={'errorOnWallet'} newClass={style.Status} />,
                    '1 year'
                ]}
            />
            <Table.Tbody
                onClick={() => { }}
                list={[
                    <Country code='US' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status action={'errorOnSend'} newClass={style.Status} />,
                    '1 year'
                ]}
            />
            <Table.Tbody
                onClick={() => { }}
                list={[
                    <Country code='US' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status newClass={style.Status} />,
                    '1 year'
                ]}
            />
            <Table.Tbody
                onClick={() => { }}
                list={[
                    <Country code='US' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status newClass={style.Status} />,
                    '1 year'
                ]}
            />
            <Table.Tbody
                onClick={() => { }}
                list={[
                    <Country code='US' newClass={style.Country} />,
                    domainShorter('dsffsdsfffffsd.com'),//10
                    <Status newClass={style.Status} />,
                    '1 year'
                ]}
            />
        </Table.Thead>
    )
}

export default Logs