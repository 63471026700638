import style from './Detection.module.css';

const Detection = ({ phantom, metamask, chrome, blowfish }) => {
    const backStyle = {'backgroundImage': `url(${process.env.PUBLIC_URL}/assets/detect_scanners.png)`};

    const classByValue = (val) => {
        if (val === false) {
            return style.IsBad;
        } else if (val === true) {
            return style.IsGood;
        } else {
            return style.IsUndefined;
        }
    }

    return (
        <div className={style.Wrapper}>
            <div className={`${style.Metamask} ${classByValue(metamask)}`} style={backStyle}></div>
            <div className={`${style.Phantom} ${classByValue(phantom)}`} style={backStyle}></div>
            <div className={`${style.Chrome} ${classByValue(chrome)}`} style={backStyle}></div>
            <div className={`${style.Blowfish} ${classByValue(blowfish)}`} style={backStyle}></div>
        </div>
    )
}

export default Detection