import style from './Grid.module.css';

const Grid = ({ children, title, header, emptyBackground, customWrapper }) => {
    return (
        <div className={`${style.Wrapper} ${customWrapper || ''} ${emptyBackground ? style.Empty : ''}`}>
            {title || header ? <>
                <div className={style.Header}>
                    {title ?
                        <div className={style.Title}>
                            {title}
                        </div> : null
                    }

                    {header || null}
                </div>

                <div className={style.Line} />
            </> : null}

            <div className={style.Padding}>
                {children}
            </div>
        </div>
    )
}

export default Grid