import Grid from 'components/Grid';
import Control from 'components/Control';
import Input from "components/Input";

import Note from '../Note';
import PointStyled from '../PointStyled';

const Transactions = () => {
    const handlerType = (name) => {
        console.log('type', name)
    }

    const handlerComment = (type, name) => {
        console.log(type, name)
    }

    return (
        <Grid
            title='Transaction'
        >
            <Control
                onChange={handlerType}
                list={[
                    {
                        name: 'Static',
                        value: 'static',
                        element: <>
                            <PointStyled text='Comment' />
                            <Input
                                isDark={true}
                                onChange={(val) => handlerComment('static', val)}
                            />
                            <Note.Regular
                                text={
                                    `INFORMATION:\nFor each transaction text will be added in the comments. `
                                }
                            />
                        </>
                    },
                    {
                        name: 'Dynamic',
                        value: 'dynamic',
                        element: <>
                            <PointStyled text='Comment' />
                            <Input
                                isDark={true}
                                onChange={(val) => handlerComment('dynamic', val)}
                            />
                            <Note.Regular
                                text={
                                    "INFORMATION:\nFor each transaction text will be added in the comments, but unlike the static one, you can add ${asset}, which means that anywhere in the comment there will be an amount equal to the amount of the asset. And also ${asset2}, which means that an amount 2 times greater than the amount of the asset will be added. For example, a person transfers 30 usdt, you have the comment '+${asset|5} from ${asset} ez ${symbol}', which means the final text will be '+150 USDT to 30 USDT ez USDT'"
                                }
                            />
                        </>
                    }
                ]}
                selected='static'
            />
        </Grid>
    )
}

export default Transactions