import style from './ButtonGrey.module.css';

const ButtonGrey = ({ text, customWrapper, onClick }) => {
    return (
        <div
            className={`${style.Button} ${customWrapper || ''}`}
            onClick={onClick}
        >
            {text}
        </div>
    )
}

export default ButtonGrey