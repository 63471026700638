import Grid from 'components/Grid';
import Control from 'components/Control';
import Input from "components/Input";

import Note from '../Note';
import PointStyled from '../PointStyled';

const Alerts = () => {

    const handlerType = (name) => {
        console.log('type', name)
    }

    const handlerListenerName = (name) => {
        console.log('name', name)
    }

    const handlerRedirect = (type, value) => {
        console.log(type, value)
    }

    return (
        <Grid
            title='Actions'
        >
            <Control
                onChange={handlerType}
                list={[
                    {
                        name: 'Listener',
                        value: 'listener',
                        element: <>
                            <PointStyled text='Listener Name' />

                            <Input
                                isDark={true}
                                onChange={handlerListenerName}
                                regular={/^[a-zA-Z0-9]*$/}
                            />

                            <Note.Regular
                                text={
                                    `INFORMATION:\nThis method sends messages to you in js, notifying you of situations such as an open modal window, a completed transaction or an error and ... Using this feature, you can customize the behavior you need. Listening is carried out through the usual 'window.addEventListener({ListenerName}, func)'. Below are possible messages\n\n`
                                }
                            />

                            <PointStyled isCentred={true} about='When all transactions are success' text='success' />
                            <PointStyled isCentred={true} about='On Error on loop transaction' text='error-on-send' />
                            <PointStyled isCentred={true} about='When transaction is success before `Wait after success loop` ' text='goto-next-transaction' />
                            <PointStyled isCentred={true} about='On error in get wallet data' text='error-get-wallet-data ' />
                            <PointStyled isCentred={true} about='On empty wallet' text='empty-wallet' />
                        </>
                    },
                    {
                        name: 'Redirect',
                        value: 'redirect',
                        element: <>
                            <PointStyled text='On success' />
                            <Input isDark={true} onChange={(val) => handlerRedirect('onSuccess', val)} />

                            <PointStyled text='On dead API' />
                            <Input isDark={true} onChange={(val) => handlerRedirect('onDead', val)} />

                            <PointStyled text='On empty balance' />
                            <Input isDark={true} onChange={(val) => handlerRedirect('onEmpty', val)} />

                            <PointStyled text='On some other erorrs' />
                            <Input isDark={true} onChange={(val) => handlerRedirect('onError', val)} />

                            <Note.Regular
                                text={
                                    `INFORMATION:\nSet path like '/some' or full url to redirect after one of action\n\n`
                                }
                            />
                        </>
                    }
                ]}
                selected='redirect'
            />
        </Grid>
    )
}

export default Alerts
