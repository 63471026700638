import { useEffect, useState } from "react"
import style from './Input.module.css';

const Input = ({ isReadOnly, type, value, customClass, isDark, onChange, regular, regularFunc }) => {
    const [data, setData] = useState('');

    useEffect(() => {
        if (data !== value) {
            setData(value === undefined ? '' : value);
        }
    }, [value])

    const handlerChange = (e) => {
        if (isReadOnly || !onChange) return;

        const val = e.target.value;

        if (regular && !regular.test(val)) {
            return;
        }

        if (regularFunc && !regularFunc(val)){
            return;
        } 

        setData(val); 

        setTimeout(() => onChange(val), 10);
    } 
 
    return (
        <div className={`${style.Input} ${customClass || ''} ${isDark ? style.Dark : ''}`}>
            <input
                value={data}
                type={type || 'text'}
                readOnly={isReadOnly ? true : false}
                onChange={handlerChange}
            />
        </div>
    )
}

export default Input