import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import WebApp from "@twa-dev/sdk";

import BearLoader from 'components/BearLoader';

import style from './Loader.module.css';
import jwtStore from "store/JWT.store";

function genWords(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const Dotted = () => {
    const [count, setCount] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((value) => {
                if (value >= 7) return 1

                return value + 1;
            })

            console.log(count);
        }, 500)

        return () => {
            clearInterval(interval)
        }
    }, [])

    const decoded = 'Loading'.substr(0, count);
    const encoded = genWords(7 - count);

    return (
        <>
            {decoded}

            <span className={style.Encoded}>{encoded}</span>
        </>
    )
}

const Loader = () => {
    const navigate = useNavigate();

    const onSuccess = ({ jwt, seconds }) => {
        jwtStore.setKey(jwt, seconds);

        navigate(`/statistic`);
    }

    const onError = () =>
        navigate(`/errorOnAuth`)


    useEffect(() => {
        if (WebApp.initData === '')
            return navigate(`/isNotTMA`);

        const timeout = setTimeout(() => {
            jwtStore.createApi(
                '/verification',
                { webApp: WebApp.initData },
                onSuccess,
                onError
            )
        }, 3000);

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    return (
        <div className={style.Wrapper}>
            <div className={style.Logo}>
                <BearLoader />
            </div>

            <div className={style.Text}>
                <Dotted />
                <br />Please wait
            </div>
        </div>
    )
}

export default Loader;