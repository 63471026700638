
function Checked() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      ariaHidden="true"
      viewBox="0 0 10 7"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 4.586L1.707 2.293A1 1 0 10.293 3.707l3 3a.997.997 0 001.414 0l5-5A1 1 0 108.293.293L4 4.586z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Checked;