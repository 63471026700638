import Line from './Line';
import Grid from '../Grid';
import style from './Challenge.module.css';

const Challenge = ({
    actualPrize,
    nextPrize,
    name,
    statName,
    progress,
}) => {
    return (
        <div>
            <Grid emptyBackground={true} customWrapper={style.Wrapper}>
                <div className={style.Prize}>
                    <div className={`${style.Round} ${style.Actual}`}>{actualPrize}</div>

                    {nextPrize ? <>
                        <div className={style.AnimateLine}>
                            <Line customClass={style.LineSvg} />
                        </div>
                        <div className={`${style.Round} ${style.Next}`}>{nextPrize}</div>
                    </> : null}
                </div>

                <div className={style.Name}>{name}</div>
                <div className={style.StatName}>{statName}</div>

                {
                    progress ?
                        <div className={style.Box}>
                            <div className={style.Top}>
                                <div className={style.ProgressText}>
                                    Progress
                                </div>

                                <div className={style.Percent}>
                                    {parseInt(progress.actual) * 100 / parseInt(progress.need)}%
                                </div>
                            </div>

                            <div className={style.Line}>
                                <div className={style.Back} style={{ width: `${parseInt(progress.actual) * 100 / parseInt(progress.need)}%` }} />
                            </div>

                            <div className={style.Bottom}>
                                <div className={style.Info}>
                                    {parseInt(progress.actual).toLocaleString(
                                        undefined, { minimumFractionDigits: 0 }
                                    )} / {parseInt(progress.need).toLocaleString(
                                        undefined, { minimumFractionDigits: 0 }
                                    )} {progress.type}
                                </div>

                                <div className={style.Left}>
                                    {progress.left} Left
                                </div>
                            </div>
                        </div>

                        : null
                }
            </Grid>
        </div>
    )
}

export default Challenge