import style from './Modal.module.css';

const Modal = ({ children, title, onClose }) => {
    return (
        <div className={style.Modal}>
            <div className={style.Header}>
                <div className={style.Title}>{title}</div>
                <div className={style.Close} onClick={onClose}>Close</div>
            </div>
            <div className={style.Overflow}>
                {children || null}
            </div>
        </div>
    )
}

export default Modal