import Name from "../components/Name";
import ReceiverAddress from "../components/ReceiverAddress";

const AddAddress = () => {
    const handlerName = (name) => {
        console.log('name', name)
    }

    const handlerAddress = (address) => {
        console.log('address', address);
    }

    const handlerNewSubList = (list) => {
        console.log('list', list);
    }

    return (
        <>
            <Name onChange={handlerName} />

            <ReceiverAddress
                mainAddress=''
                subList={[]}
                onMainChange={handlerAddress}
                onSubChange={handlerNewSubList}
            />
        </>
    )
}

export default AddAddress;