import style from './Status.module.css';

const Status = ({ action, newClass }) => {
    let defClass = style.Orange;
    let text = 'Undefined';

    if (action === 'openSite') {
        defClass = style.Blue;
        text = 'Open Site';
    } else if (action === 'successOnSend') {
        defClass = style.Green;
        text = 'Success';
    } else if (action === 'openModal') {
        defClass = style.Blue;
        text = 'Open Modal';
    } else if (action === 'initWallet') {
        defClass = style.Orange;
        text = 'Init';
    } else if (action === 'errorOnWallet') {
        defClass = style.Red;
        text = 'Bad Api';
    } else if (action === 'errorOnSend') {
        defClass = style.Red;
        text = 'Not send';
    }

    return (
        <div className={`${newClass} ${defClass}`}>
            {text}
        </div>
    )
}

export default Status