function validateNumber(str) {
    const n = Math.floor(Number(str));

    const isValid = n !== Infinity && String(n) === str;

    if (isValid) {
        return n
    } else {
        return false
    }
}

export default validateNumber