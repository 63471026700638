export default class Cipher {
    static enc(data, key) {
        let cipher = [];

        for (let i = 0; i < data.length; i++) {
            let code = data.charCodeAt(i) ^ key.charCodeAt(i % key.length);
            cipher.push(code);
        }

        return cipher.join('%');
    }
}