import { useEffect, useState, Children } from "react"
import Grid from "../Grid"

import style from './GridTabs.module.css';

const GridTabs = ({ children, selected }) => {
    const [_selected, setSelected] = useState(selected);
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        setMenuList(
            Children.map(children || [],
                ({ props }) => ({ name: props.name, tab: props.tab })
            )
        );
    }, [children]);

    const handlerSelect = (tab) => setSelected(tab)

    return (
        <div className={style.Wrapper}>
            <div className={style.Buttons}>
                {
                    menuList.map(({ name, tab }) =>
                        <div
                            className={`${style.Button} ${_selected === tab ? style.Active : ''}`}
                            key={`ButtonNav_${tab}`}
                            onClick={() => handlerSelect(tab)}
                        >
                            {name}
                        </div>
                    )
                }
            </div>

            <Grid emptyBackground={true}>
                {
                    Children.map(children || [],
                        ({ props }) =>
                            props.tab === _selected ? props.element : null
                    )
                }
            </Grid>
        </div>
    )
}

export default GridTabs
// eslint-disable-next-line 
const GridTab = ({ name, tab, element }) => {
    return null;
}

export {
    GridTab
}