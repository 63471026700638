import Shelf from 'components/Shelf';
import GridPages from 'components/GridPages';
import { GridPage } from 'components/GridPages';

import style from './Style.module.css';

const Actions = () => {

    const builded = <div className={style.List}>
        <Shelf title='Withdraw' about='Count withdraw money to your accounts, with discount deduction' count='1000' symbol='$' />
        <Shelf title='Visitors' about='Count visitors by action "openSite"' count='1000' />
        <Shelf title='Success Send' about='Count success send by "successOnSend"' count='10300' />
        <Shelf title='Empty Balance' about='Count empty balance, calc by action "initWallet"' count='100' />
        <Shelf title='Errors Connect' about='Count errors by action "errorOnWallet"' count='1000' />
        <Shelf title='Errors Send' about='Count errors by action "errorOnSend"' count='10300' />
    </div>

    return (
        <GridPages
            title='Actions'
            selected='1d'
        >
            <GridPage name='1d' element={builded} />
            <GridPage name='30d' element={builded} />
            <GridPage name='all' element={builded} />
        </GridPages>
    )
}

export default Actions