import { Children } from "react"
import Tbody from "../Tbody";

import style from './Thead.module.css';

const Thead = ({ names, children }) => {
    return (
        <table className={style.Table}>
            <thead className={style.Thead}>
                <tr>
                    {
                        names.map((name) => <td key={`Thead_${name}`} className={style.Td}>{name}</td>)
                    }
                </tr>
            </thead>
            
            <tbody className={style.Tbody}>
                {
                    Children.map(children || [],
                        ({ props }, i) =>
                            <tr
                                className={`${style.Tr} ${props.onClick ? style.TrClick : ''}`}
                                onClick={props.onClick ? props.onClick : undefined}
                            >
                                <Tbody key={`Tbody_${i}`} list={props.list} />
                            </tr>
                    )
                }
            </tbody>
        </table>
    )
}

export default Thead;