import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react"

import style from './Menu.module.css';

const Menu = ({ onClose }) => {
    const [page, setPage] = useState('statistic');
    const [hide, setHide] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const page = location.pathname.split('/');

        setPage(page[1]);
    }, [location])


    const list = [
        {
            name: 'Statistic',
            path: 'statistic'
        }, {
            name: 'Ton Drainer',
            path: 'ton'
        }, {
            name: 'Contacts',
            path: 'contacts'
        }
    ];

    const handlerOpen = (path) => {
        navigate(`/${path}`);
        onHide();
    }

    const onHide = () => {
        setHide(true);
        setTimeout(() => { onClose(); }, 800);
    }

    return (
        <div className={`${hide ? style.Hide : ''} ${style.Wrapper}`}>
            <div className={style.Header}>
                <div className={style.Title}>Menu</div>

                <div
                    className={style.Close}
                    onClick={onHide}
                />

            </div>

            <div className={style.Line}></div>

            <div className={style.List}>
                {
                    list.map(({ name, path }) =>
                        <div
                            key={`Menu_${path}`}
                            className={`${path === page ? style.Active : ''} ${style.Link}`}
                            onClick={() => handlerOpen(path)}
                        >
                            {name}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Menu