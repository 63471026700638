import Actions from './Actions';
import Discount from './Discount';

const Statistic = () => {
    return (
        <>
            <Discount />

            <Actions />
        </>
    )
}

export default Statistic