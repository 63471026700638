import style from './GridNumbered.module.css';

const GridNumbered = ({ num, underNum, children }) => {
    return (
        <div className={style.Wrapper}>
            <div className={style.Left}>
                <div className={style.Num}>{num}.</div>
                {underNum}
            </div>

            <div className={style.Right}>
                {children}
            </div>
        </div>
    )
}

export default GridNumbered;