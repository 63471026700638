import { useState } from "react"

import BearLoader from 'components/BearLoader';
import Menu from './Menu';

import style from './Header.module.css';

const Header = () => {
    const [menu, setMenu] = useState(false);

    const onOpen = () => {
        setMenu(true);
    }

    const onClose = () => {
        setMenu(false)
    }

    return (
        <>
            <div className={style.Wrapper} >
                <div className={style.Elements}>
                    <div className={style.Logo}>
                        <BearLoader />
                    </div>

                    <div className={style.Title}>
                        Bear
                        <span className={style.Title2}>Pre-Alpha</span>
                    </div>


                    <div
                        className={style.Burger}
                        onClick={onOpen}
                    >
                        <span></span>
                    </div>
                </div>

                {menu ? <Menu onClose={onClose}/> : null}

                <div className={style.Line} />
            </div>
        </>
    )
}

export default Header