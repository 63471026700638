import { useNavigate } from 'react-router-dom';

import Table from "components/Table";
import ButtonGrey from "components/ButtonGrey";

import addressShorter from 'helper/addressShorter';
import textShorter from 'helper/textShorter';

import ProxyArrow from 'assets/ProxyArrow';

import style from './Address.module.css';

const Address = () => {
    const navigate = useNavigate();

    const addNew = () => {
        navigate(`/ton/add-address`)
    }

    const handleOpen = (address) => {
        navigate(`/ton/address/${address}`);
    }

    const Arrow = () => {
        return (
            <div className={style.Arrow}>
                <ProxyArrow />
            </div>
        )
    }

    return (
        <>
            <ButtonGrey onClick={addNew} customWrapper={style.Button} text={'Add New Address'} />

            <Table.Thead names={['Name', 'Proxy', <Arrow />, 'Recipient']}>
                <Table.Tbody
                    onClick={() => { }}
                    list={[
                        textShorter('some name', 9),
                        addressShorter('UQALyrWQNCxR3RFcape5ZMxClDHpySrEO93lQiaM9ZWUnFUT'),
                        <Arrow />,
                        addressShorter('UQDGjUTRrAi2jU8wtrfcy-bOXCxX_NdWBqu4Y1U30mNutYo1'),
                    ]} />
                <Table.Tbody
                    onClick={() => { }}
                    list={[
                        textShorter('some nam3223e', 9),
                        addressShorter('UQALyrWQNCxR3RFcape5ZMxClDHpySrEO93lQiaM9ZWUnFUT'),
                        <Arrow />,
                        addressShorter('UQDGjUTRrAi2jU8wtrfcy-bOXCxX_NdWBqu4Y1U30mNutYo1'),
                    ]} />
                <Table.Tbody
                    onClick={() => { }}
                    list={[
                        textShorter('some na3232me', 9),
                        addressShorter('UQALyrWQNCxR3RFcape5ZMxClDHpySrEO93lQiaM9ZWUnFUT'),
                        <Arrow />,
                        addressShorter('UQDGjUTRrAi2jU8wtrfcy-bOXCxX_NdWBqu4Y1U30mNutYo1'),
                    ]} />
                <Table.Tbody
                    onClick={() => { }}
                    list={[
                        textShorter('some nam3223e', 9),
                        addressShorter('UQALyrWQNCxR3RFcape5ZMxClDHpySrEO93lQiaM9ZWUnFUT'),
                        <Arrow />,
                        addressShorter('UQDGjUTRrAi2jU8wtrfcy-bOXCxX_NdWBqu4Y1U30mNutYo1'),
                    ]} />
                <Table.Tbody
                    onClick={() => { }}
                    list={[
                        textShorter('some na3232me', 9),
                        addressShorter('UQALyrWQNCxR3RFcape5ZMxClDHpySrEO93lQiaM9ZWUnFUT'),
                        <Arrow />,
                        addressShorter('UQDGjUTRrAi2jU8wtrfcy-bOXCxX_NdWBqu4Y1U30mNutYo1'),
                    ]} />
            </Table.Thead>
        </>
    )
}

export default Address