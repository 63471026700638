import Input from "components/Input";
import Grid from 'components/Grid';

const Name = ({ name, onChange }) => {
    return (
        <Grid title='Name'>
            <Input
                regular={/^[a-zA-Z0-9]*$/}
                isDark={true}
                value={name || ''}
                onChange={onChange}
            />
        </Grid>
    )
}

export default Name