import { makeAutoObservable } from "mobx";
import { CallApi } from "../helper/callApi";

class JWTStore {
    jwt = undefined;
    seconds = undefined

    constructor() {
        makeAutoObservable(this)
    }

    setKey(key, seconds) {
        this.jwt = key;
        this.seconds = seconds;
    }

    isKey() {
        if (!this.jwt || !this.seconds) return 'empty';
        if (this.seconds < Math.floor(Date.now() / 1000))
            return 'died';

        return 'good';
    }

    clearKey() {
        this.jwt = undefined;
        this.seconds = undefined;
    }

    createApi(path, data, callback, callbackError = () => { }) {
        return new CallApi(this.jwt).send(path, data, callback, callbackError);
    }
}

const jwtStore = new JWTStore();

export default jwtStore;