import Challenge from 'components/Challenge';

const Discount = () => {
    return (
        <>
            <Challenge
                actualPrize='20%'
                name='Discount to withdraw'
                statName='Final'
                progress={undefined}
            />

            <Challenge
                actualPrize='20%'
                nextPrize='15%'
                name='Discount to withdraw'
                statName='2 000$ / 15 days'
                progress={
                    {
                        type: '$',
                        left: '13 days',
                        need: 2000,
                        actual: 1300
                    }
                }
            />
        </>
    )
}

export default Discount;