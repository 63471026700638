
function Arrow({customClass}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 28">
            <path
                className={customClass}
                fill="none"
                d="M0 25q100-46 200 0"
            ></path>
        </svg>
    );
}

export default Arrow;


/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 28"> <path d="M 0 25 Q 100 -21 195 25" fill="none" stroke="white" marker-end="url(#arrow)"></path> <defs> <marker id="arrow" markerWidth="8" markerHeight="8" refX="2" refY="3.5" orient="auto" markerUnits="strokeWidth"> <path d="M0,0 L0,6 L6,3 z" fill="white"></path> </marker> </defs> </svg> */