import Point from "components/Point";

import style from './PointStyled.module.css';

const PointStyled = ({ text, about }) => {
    return (
        <Point
            isCentred={true}
            name={text}
            value={about || ''}
            className={style.Point}
        />
    )
}

export default PointStyled