import { useState } from "react"
import style from './TextArea.module.css';

const TextArea = ({ isReadOnly, type, value, customClass }) => {
    const [data, setData] = useState(value || '');

    return (
        <div className={`${style.TextArea} ${customClass || ''}`}>
            <textarea type={type || 'text'} readOnly={isReadOnly ? true : false}>
                {data}
            </textarea>
        </div>
    )
}

export default TextArea