import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import Header from 'features/Header';

import style from './IsAuthorize.module.css';
import jwtStore from 'store/JWT.store';

const IsAuthorize = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const check = jwtStore.isKey();

        if (check === 'died') {
            navigate('/inValid');
        } else if (check === 'empty') {
            navigate('/');
        }
    }, []);

    return (
        <div className={style.Scrolled}>
            <Header />

            <div className={style.Wrapper}>
                {children}
            </div>
        </div>
    )
}

export default IsAuthorize;