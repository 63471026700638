import Grid from '../Grid';
import style from './Shelf.module.css';

const Shelf = ({ count, title, about, symbol }) => {
    return (
        <Grid emptyBackground={true} customWrapper={style.Wrapper}>
            <div className={style.Title}>{title}</div>
            <div className={style.Count}>
                {parseInt(count).toLocaleString(
                    undefined, { minimumFractionDigits: 0 }
                )}{symbol ? ' $' : ''}
            </div>
            <div className={style.About}>{about}</div>
        </Grid>
    )
}

export default Shelf