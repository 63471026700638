import { useNavigate } from 'react-router-dom';

import Table from "components/Table";
import Detection from "components/Detection";
import ButtonGrey from "components/ButtonGrey";

import domainShorter from 'helper/domainShorter';

import style from './DrainerWithOffer.module.css';

const DrainerWithOffer = () => {
    const navigate = useNavigate();

    const addNew = () => {
        navigate(`/ton/offered-add-domain`)
    }

    const handleOpen = (domain) => {
        navigate(`/ton/drainer/${domain}`);
    }

    return (
        <>
            <ButtonGrey onClick={addNew} customWrapper={style.Button} text={'Add New Domain + Offer'} />
           
            <Table.Thead names={['Domain', 'Offer', <Detection
                phantom={true}
                metamask={true}
                chrome={true}
                blowfish={true}
            />, 'Logs']}>
                <Table.Tbody
                    onClick={() => { }}
                    list={[
                        domainShorter('dsffsdsfffffsd.com'),
                        'Ston.fi',
                        <Detection
                            phantom={false}
                            metamask={true}
                            chrome={undefined}
                        />,
                        '4'
                    ]} />
                <Table.Tbody
                    onClick={() => { }}
                    list={[
                        domainShorter('dsffsdsfffffsd.com'),
                        'Ston.fi',
                        <Detection
                            phantom={undefined}
                            metamask={false}
                            chrome={true} />,
                        '4'
                    ]}
                />
                <Table.Tbody
                    onClick={() => { }}
                    list={[
                        domainShorter('dsffsdsfffffsd.com'),
                        'Ston.fi',
                        <Detection
                            phantom={true}
                            metamask={undefined}
                            chrome={false} />,
                        '4'
                    ]}
                />
            </Table.Thead>
        </>
    )
}

export default DrainerWithOffer