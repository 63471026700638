import { useState } from "react"

import Checked from "assets/Checked";

import style from './ModalSelect.module.css';
import Modal from "../Modal";

const ModalSelect = ({ list, selected, onChange, emptyText }) => {
    const [_selected, setSelected] = useState(selected ? selected : undefined);
    const [isModalOpen, setIsOpenModal] = useState(false);

    const onSelect = (key) => {
        //onChange(key)
        setSelected(key)
        toggleModal();
    }

    const toggleModal = () => {
        setIsOpenModal(!isModalOpen);
    }
    return (
        <>
            {
                isModalOpen ?
                    <Modal title={'some title'} onClose={toggleModal}>
                        {
                            list.length === 0 ?
                                <div className={style.Empty}>{emptyText}</div> 
                                : list.map(
                                    ({ rowName, img, name, about }, index) =>
                                        <Row
                                            key={`ModalSelect_${index}`}
                                            onClick={() => onSelect(rowName)}
                                            img={img}
                                            name={name}
                                            about={about}
                                            isActive={(rowName === _selected)}
                                        />
                                )
                        }
                    </Modal>
                    : null
            }
            {
                _selected ? <Row
                    onClick={toggleModal}
                    isActive={undefined}
                    {...list.find(({rowName}) => rowName === _selected)}
                /> : <Row
                    isActive={undefined}
                    onClick={toggleModal}
                    img=''
                    name='Not selected'
                    about='Click to select'
                />
            }
        </>
    );
}

const Row = ({ img, name, about, isActive, onClick }) => {
    return (
        <div
            className={`${isActive === true ? style.Active : ''} ${onClick ? style.Clickable : ''} ${style.Row}`}
            onClick={onClick ?? undefined}
        >
            <div className={style.Image}>
                {
                    typeof img === 'string' ?
                        <img src={img} alt='' />
                        : img
                }
            </div>

            <div className={style.Text}>
                <div className={style.About}>{about}</div>
                <div className={style.Name}>{name}</div>
            </div>

            {
                isActive !== undefined ?
                    <div className={style.Box}>{isActive ? <Checked /> : null}</div>
                    : null
            }

        </div>
    );
}

export default ModalSelect;