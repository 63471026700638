import { useEffect, useState } from "react"

import style from './Control.module.css';

const Control = ({ list, selected, customStyle, onChange }) => {
    const [_selected, setSelected] = useState(selected);
    const [_element, setElement] = useState(null);

    useEffect(() => {
        const finded = list.find(({ element, value }) => {
            if (!element) return false;

            if (value === _selected) {
                return true;
            } else {
                return false;
            }
        });

        if (finded) {
            setElement(finded.element)
        } else {
            setElement(null)
        }
    }, [_selected]);

    const handlerClick = (value) =>{
        setSelected(value);

        if(onChange) onChange(value);
    }

    return (
        <>
            <div className={`${customStyle || ''} ${style.Control}`}>
                {
                    list.map(
                        ({ name, value }) =>
                            <ControlPoint
                                isSelected={value === _selected}
                                name={name}
                                value={value}
                                onClick={handlerClick}
                                key={`ControlPoint${value}`}
                            />
                    )
                }
            </div>

            {_element ? _element: null}
        </>
    )
}

const ControlPoint = ({ name, value, isSelected, onClick }) => {
    return (
        <div
            className={`${style.Point} ${isSelected ? style.Active : ''}`}
            onClick={() => onClick(value)}
        >
            {name}
        </div>
    )
}

export default Control