import Grid from 'components/Grid';
import Input from "components/Input";

import validateNumber from 'helper/validateNumber';

import PointStyled from "../PointStyled";

const System = () => {
    const handlerError = (name) => {
        console.log('type', name)
    }

    const handlerLoop = (name) => {
        console.log('type', name)
    }

    const handlerSuccess = (name) => {
        console.log('type', name)
    }
    
    return (
        <Grid title='System config'>
            <PointStyled text='Retry on error' about={'min - 3, max - 10'} />
            <Input
                regularFunc={(str) => {
                    if (str === '0') return false;
                    if (str === '') return true;
                    if (str === '1') return true;

                    const n = validateNumber(str);

                    if (!n) return false;

                    return n >= 0 && n >= 3 && n <= 10;
                }}
                isDark={true}
                onChange={handlerError}
            />

            <PointStyled text='Max transaction (loop)' about={'min - 2, max - 5'} />
            <Input
                regularFunc={(str) => {
                    if (str === '0') return false;
                    if (str === '') return true;

                    const n = validateNumber(str);

                    if (!n) return false;

                    return n >= 0 && n >= 2 && n <= 5;
                }}
                isDark={true}
                onChange={handlerLoop}
            />

            <PointStyled text='Wait after success loop' about={'min - 2000 ms'} />
            <Input
                regularFunc={(str) => {
                    if (str === '0') return false;
                    if (str === '') return true;

                    const n = validateNumber(str);

                    if (!n) return false;

                    return n >= 0;
                }}
                isDark={true}
                onChange={handlerSuccess}
            />
        </Grid>
    )
}

export default System