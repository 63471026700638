import style from './Error.module.css';

const IsNotTMA = ({text}) => {
    return (
        <div className={style.Wrapper}>
            <div className={style.Text}>
                {text}
            </div>
        </div>
    )
}

export default IsNotTMA