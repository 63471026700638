const domainShorter = (domain) => {
    const spl = domain.split('.');

    const zone = spl[spl.length - 1];

    spl.pop();

    let str = spl.join('.');

    if (str.length > 10) {
        str = str.slice(0, 10) + '..';
    }

    return `${str}.${zone}`;
}

export default domainShorter;