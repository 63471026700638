import Grid from 'components/Grid';
import ModalSelect from 'components/ModalSelect';

import Note from '../Note';

const Offer = ({ saved }) => {
    return (
        <>
            {
                !saved ?
                    <Grid title='Select Offer'>

                        <ModalSelect
                            list={[
                                { rowName: 'a', img: 'https://asset.brandfetch.io/idPLU9MIIz/id_I1FjN8I.jpeg', name: 'StonFi connect', about: 'ston.fi swap (drain on connect)' },
                                { rowName: 'b', img: 'https://asset.brandfetch.io/idPLU9MIIz/id_I1FjN8I.jpeg', name: 'StonFi swap', about: 'ston.fi swap (drain on swap)' },
                                { rowName: 'c', img: '', name: 'Dedust connect', about: 'dedust.io swap (drain on connect)' },
                                { rowName: 'd', img: '', name: 'Dedust swap', about: 'dedust.io swap (drain on swap)' }
                            ]}
                            selected='d'
                            onChange={() => { }}
                        />

                        <Note.Red
                            text={'* Basic settings are provided with each offer; we do not recommend changing them. Any changes may disrupt the interaction between drainer and the offer. In the event of a breakdown, solving such problems in a short time is not important to us.'}
                        />
                    </Grid>
                    : null
            }
        </>
    )
}

export default Offer