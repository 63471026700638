

const validateAddressTon = (address) => {
    if (address === '') return true;

    const firstChar = address[0];
    const secondChar = address[1];

    if (['0', 'U', 'E'].indexOf(firstChar) === -1) return false;

    if (address.length === 1) return true;

    if ([':', 'Q'].indexOf(secondChar) === -1) return false;
    if (address.length === 2) return true;

    if ((firstChar === 'U' || firstChar === 'E') && address.length > 48) return false;
    if (firstChar === '0' && address.length > 66) return false;

    return true;
}

export default validateAddressTon;