import { hasFlag } from 'country-flag-icons'

import style from './Country.module.css';

const Country = ({ code, newClass }) => {
    const upperCode = code.toUpperCase();

    let uri = `${process.env.PUBLIC_URL}/assets/pirate.png`

    if (hasFlag(upperCode)) {
        uri = `http://purecatamphetamine.github.io/country-flag-icons/3x2/${upperCode}.svg`;
    }

    return (
        <div className={`${newClass} ${style.Country}`}>
            <img
                alt=""
                src={uri} />
        </div>
    )
}

export default Country