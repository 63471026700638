import { useEffect, useState, forwardRef, useImperativeHandle, createRef } from "react";

import Input from "components/Input";
import Grid from 'components/Grid';
import ButtonGrey from 'components/ButtonGrey';

import PointStyled from "../PointStyled";
import Note from "../Note";

import style from './ReceiverAddress.module.css';
import validateAddressTon from "helper/validateAddressTon";

const ReceiverAddress = ({ mainAddress, subList, onMainChange, onSubChange }) => {
    const [list, setList] = useState(subList || [])
    const refShare = createRef();

    useEffect(() => {
        listSend();
    }, [])

    const handlerAddMore = () => {
        if (list.length >= 4) return;

        setList(oldArray => [...oldArray, { address: '', percent: 0 }]);
        listSend();
    }

    const handlerDelete = (id) => {
        setList(oldArray => oldArray.filter((_, index) => index !== id))
        listSend();
    }

    const handlerSubAddress = (newList) => {
        setList(newList);
        listSend();
    }

    const listSend = () => {
        onSubChange(list);

        const newShare =
            list.reduce(
                (prev, curr) => {
                    return prev - curr.percent
                }, 100
            );


        refShare.current?.add(newShare);
    }

    return (
        <Grid title='Receiver'>
            <Share ref={refShare} />

            <Input
                regularFunc={validateAddressTon}
                isDark={true}
                value={mainAddress} 
                onChange={onMainChange}
            />

            <Note.Red
                text={'* Below you can add additional addresses for profit distribution'}
            />

            <SubAddresses list={list} onChange={handlerSubAddress} onDelete={handlerDelete} />

            <More isVisible={list.length < 4} onClick={handlerAddMore} />

        </Grid>
    )
}

const Share = forwardRef(({ }, ref) => {
    const [mainShare, setMainShare] = useState(0)

    useImperativeHandle(ref, () => ({
        add: (num) => {
            setMainShare(num)
        }
    }));

    return (
        <PointStyled
            text='Main Address'
            about={`Share ${mainShare} %`}
        />
    )
})

const More = ({ isVisible, onClick }) => {
    if (isVisible) {
        return <ButtonGrey onClick={onClick} text={'Add extra Address'} />
    } else {
        return null
    }
}

const SubAddresses = ({ list, onChange, onDelete }) => {

    const handlerChange = (type, index, newData) => {
        let _list = list;

        if (type === 'address') {
            _list[index].address = newData;

            onChange(_list)
        } else if (type === 'percent') {
            _list[index].percent = parseInt(newData === '' ? '0' : newData);

            onChange(_list)
        }
    }

    const Inputs = ({ address, percent, index }) => {
        return (
            <>
                <PointStyled
                    text={
                        <>
                            {`Sub Address #${index + 1}`}

                            <div className={style.Delete} onClick={() => onDelete(index)} >(Delete)</div>
                        </>
                    }
                    about={`Share`}
                />

                <div className={style.Inputs} >
                    <Input
                        regularFunc={validateAddressTon}
                        isDark={true}
                        value={address}
                        onChange={(newData) => handlerChange('address', index, newData)}
                    />

                    <Input
                        regular={/^100$|^$|^[0-9]{1,2}$/}
                        isDark={true}
                        value={percent}
                        onChange={(newData) => handlerChange('percent', index, newData)}
                    />
                </div>
            </>
        )
    }

    return (
        <>
            {
                list.map(({ address, percent }, index) => {
                    return (
                        <Inputs
                            key={`RecAddress_${index}`}
                            address={address}
                            percent={percent}
                            index={index}
                        />
                    )
                })
            }
        </>
    )
}



export default ReceiverAddress