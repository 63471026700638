import style from './ButtonNav.module.css';

const ButtonNav = ({ isActive, text, onClick }) => {
    return (
        <div
            className={isActive ? style.ActiveButton : style.Button}
            onClick={onClick}
        >
            {text}
        </div>
    )
}

export default ButtonNav