import Grid from 'components/Grid';
import Input from "components/Input";
import ModalSelect from 'components/ModalSelect';

import validateNumber from 'helper/validateNumber';
import isValidHostname from 'is-valid-hostname'

import PointStyled from "../PointStyled";

const Main = ({ saved }) => {

    const handlerSuccess = (name) => {
        console.log('type', name)
    }

    const handlerDomain = (name) => {
        console.log('domain', name)
    }

    const handlerOffer = (name) => {
        console.log('handlerOffer', name)
    }

    return (
        <>
            {
                saved ?
                    <Grid title='About' emptyBackground={true}>

                    </Grid> : null
            }

            <Grid title='Main config'>
                {
                    !saved ? <>
                        <PointStyled text='Domain' about={'unchangeable'} />

                        <Input
                            onChange={handlerDomain}
                            isDark={true}
                            regularFunc={(str) => {
                                if (str === '') return true;

                                return isValidHostname(str)
                            }}
                        />
                    </> : null
                }

                <PointStyled text='Address to withdraw' />

                <ModalSelect
                    list={[
                        { rowName: 'a', img: '', name: 'Some Name', about: 'UQAL...nFUT > UQDG...tYo1' },
                        { rowName: 'f', img: '', name: 'Gaggi', about: 'UQAL...nFUT > UQDG...tYo1' },
                        { rowName: 'v', img: '', name: 'Sosochki', about: 'UQAL...nFUT > UQDG...tYo1' }

                    ]}
                    emptyText='At first you must create address'
                    onChange={handlerOffer}
                />

                <PointStyled text='Min ton to withdraw' about={'min - 0.5 ton'} />

                <Input
                    isDark={true}
                    onChange={handlerSuccess}
                    regularFunc={(str) => {
                        if (str === '0') return true;
                        if (str === '') return true;

                        const spl = str.split('.');

                        if (spl.length >= 3 || spl.length === 0) return false;

                        if (spl[0] !== '0') {
                            const f = validateNumber(spl[0]);

                            if (!f) return false;
                        }

                        if (spl[1] === '') return true;

                        if (spl.length === 2) {
                            console.log(spl[1]);
                            const s = validateNumber(spl[1]);

                            if (!s) return false;

                            if (s > 9) return false;

                            if (spl[0] === '0' && s <= 4) return false;
                        }

                        return true;
                    }}
                />

                <PointStyled text='Min asset to withdraw' about={'min - 10 usd'} />

                <Input
                    isDark={true}
                    onChange={handlerSuccess}
                    regularFunc={(str) => {
                        if (str === '0') return false;
                        if (str === '') return true;

                        const n = validateNumber(str);

                        if (!n) return false;

                        return n >= 0;
                    }}
                />
            </Grid>
        </>
    )
}
export default Main