import Main from "../components/Main";
import System from "../components/System";
import Alerts from "../components/Alerts";
import Offer from "../components/Offer";
import Transactions from "../components/Transactions";

const DrainerOffered = () => {
    return (
        <>
            <Offer />

            <Main />

            <System />

            <Transactions />
            
            <Alerts />
        </>
    );
}

export default DrainerOffered;