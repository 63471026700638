import style from './Point.module.css';

const Point = ({ name, value, onClick, isCentred, className }) => {
    return (
        <div className={`${isCentred ? style.Centred : ''} ${className || ''} ${style.Point}`}>
            <div className={style.Name}>
                {name}:
            </div>
            <div
                className={`${style.Value} ${onClick ? style.Click : ''}`}
                onClick={onClick ? onClick : undefined}
            >
                {value}
            </div>
        </div>
    )
}

export default Point