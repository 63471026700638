import style from './Tbody.module.css';

const Tbody = ({ list }) => {
    return (
        <>
            {
                list.map(
                    (td, i) =>
                        <td key={`Td_${i}`} className={style.Td}>
                            {td}
                        </td>
                )
            }
        </>
    )
}

export default Tbody;