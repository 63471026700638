import ContactCard from 'components/ContacCard';
import Grid from 'components/Grid';

import style from './Contacts.module.css';

const Contacts = () => {
    return (
        <>
            <Grid title="Note" >
                <div className={style.Note}>
                    To verificate that admin is not a fake, in letter write <span>'I want verificate'</span> and send <span>Code</span> (you can find near contact in this page).
                    After in answer you get '<span>Result</span>', check is it equal with code <span>'Result'</span> in this page

                    <br /><br />
                    *Note, code updates every 1 hour
                </div>
            </Grid>

            <ContactCard
                src={`${process.env.PUBLIC_URL}/assets/avatar1.jpg`}
                name='Den Guala'
                about={
                    <>
                        <div className={style.Point}>Code: <span>673B5CD4B03F</span></div>
                        <div className={style.Point}>Result: <span>H5B563</span></div>
                    </>
                }
                link='https://t.me/'
            />
        </>
    )
}

export default Contacts;