import { useEffect, useState, Children } from "react"
import Grid from "../Grid"
import ButtonNav from "../ButtonNav"

import style from './GridPages.module.css';

const GridPages = ({ title, children, selected }) => {
    const [_selected, setSelected] = useState(selected);
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        setMenuList(
            Children.map(children || [],
                ({ props }) => props.name
            )
        );
    }, [children]);

    const handlerSelect = (page) => setSelected(page)

    return (
        <Grid
            title={title}
            header={
                <div className={style.Buttons}>
                    {
                        menuList.map(p =>
                            <ButtonNav
                                key={`ButtonNav_${p}`}
                                onClick={() => handlerSelect(p)}
                                isActive={_selected === p}
                                text={p}
                            />
                        )
                    }
                </div>
            }
        >
            {
                Children.map(children || [],
                    ({ props }) =>
                        props.name === _selected ? props.element : null
                )
            }
        </Grid>
    )
}

export default GridPages
// eslint-disable-next-line 
const GridPage = ({ name, element }) => {
    return null;
}

export {
    GridPage
}