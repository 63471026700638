import axios from 'axios';
import Cipher from './Cipher';


export class CallApi {
    token = undefined;

    constructor(token = undefined) {
        this.token = token;
    }

    send(path, data, callback, callbackError = () => { }) {
        const strData = JSON.stringify(data);
        const cache = Date.now();
        const authorize = this.token ?
            { 'Authorization': `Bearer ${this.token}` } : {}

        axios({
            method: 'post',
            url: `https://${process.env.REACT_APP_API_SERVER || ''}${path}`,
            data: `${cache}:${Cipher.enc(strData, `${cache}`)}`,
            timeout: 3000,
            headers: Object.assign(
                { 'Content-Type': 'text/plain' },
                authorize
            )
        })
            .then(response => {
                if (response.data.success)
                    return callback(response.data);

                if (response.data.message) {
                    callbackError();

                    //TODO ERROR ALERT by message response.data.message

                    return;
                }

                throw new Error('Undefined error');
            })
            .catch(error => {
                callbackError();

                //TODO ERROR Alert 'Undefined error'
            });
    }
}

const callApi = new CallApi();

export default callApi;