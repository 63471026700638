import Main from "../components/Main";
import System from "../components/System";
import Alerts from "../components/Alerts";
import Transactions from "../components/Transactions";

const Drainer = () => {
    return (
        <>
            <Main />

            <System />
            
            <Transactions />

            <Alerts />
        </>
    );
}

export default Drainer;