function ProxyArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path
                fillRule="evenodd"
                d="M12 6.25a.75.75 0 01.75.75v10a.75.75 0 11-1.5 0V7a.75.75 0 01.75-.75zM6.065 8.399a.75.75 0 011.06.02l2.953 3.06c.28.29.28.751 0 1.042l-2.953 3.06a.75.75 0 11-1.08-1.04l1.728-1.79H4a.75.75 0 110-1.5h3.773L6.046 9.458a.75.75 0 01.019-1.06zM16.526 8.399a.75.75 0 011.06.02l2.954 3.06c.28.29.28.751 0 1.042l-2.953 3.06a.75.75 0 11-1.08-1.04l1.727-1.79-3.772-.001a.75.75 0 010-1.5h3.773l-1.728-1.79a.75.75 0 01.02-1.061z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default ProxyArrow;
