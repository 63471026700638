import Router from 'pages/Router';

import style from './App.module.css';

const App = () => {
  return (
    <div className={style.Wrapper}>
      <Router />
    </div>
  );
}

export default App;