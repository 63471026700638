
import style from './Note.module.css';

const Red = ({ text }) => {
    return (<div className={style.Red}>{text}</div>)
}

const Regular = ({ text }) => {
    return (<div className={style.Regular}>{text}</div>)
}

export default { Red, Regular }